import { createStore } from 'redux'

let _user = {};
try {
    _user = JSON.parse(localStorage.getItem('loggedIn'));
} catch (error) {}

const initialState = {
  sidebarShow: true,
  loggedIn: _user,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      localStorage.setItem('loggedIn', JSON.stringify(state.loggedIn));
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
